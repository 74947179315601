import React from "react"
import { useColorMode } from "theme-ui"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import KarteFluesse from "../../../../../images/kurse/karte-fluesse.svg"
import KarteFluesseLight from "../../../../../images/kurse/karte-fluesse-light.svg"
import FloatContainer from "../../../../../components/float-container"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })
  const [colorMode] = useColorMode()

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/bewegungen" />
      }
      navigation={navigation}
    >
      <Seo title="Landschaften & Orte markieren" />
      <Stack>
        <Heading as="h2" level={2}>
          Landschaften & Orte markieren
        </Heading>
        <Paragraph>
          Unter den Beschreibungen des Gedichts sind einige geografische
          Referenzen eingestreut, die darauf hinweisen, dass Hölderlin die
          Landschaft um Bordeaux gut kannte und beim Schreiben vor Augen hatte.
          Das war zu Hölderlins Zeit keineswegs selbstverständlich. Nicht jeder
          hatte die Möglichkeit, auf Reisen zu gehen. Viele Schriftsteller*innen
          entwarfen in ihren Werke Landschaften, die sie lediglich aus
          Erzählungen oder Gemälden kannten.
        </Paragraph>
        <Box>
          <FloatContainer width="40%">
            <Box
              sx={{
                width: "100%",
                display: "block",
                borderWidth: 4,
                borderStyle: "solid",
                borderColor: "contrast",
              }}
            >
              <img
                src={colorMode === "light" ? KarteFluesseLight : KarteFluesse}
                alt="Stilisierte Landkarte der Flüße um Bordeaux"
              />
            </Box>
          </FloatContainer>
          <Paragraph mb={[6, 12]}>
            ›Andenken‹ folgt dem Lauf der Garonne von Bordeaux bis zu der
            »luftigen Spiz’«, mit der wahrscheinlich die Landspitze Bec d'Ambès
            gemeint ist, an der die Garonne und die Dordogne zur Gironde
            zusammenfließen. Von dort aus ist es nicht mehr weit bis die Gironde
            in den Atlantik mündet.
          </Paragraph>
          <Paragraph>
            In seiner Zeit als Hauslehrer in Bordeaux muss Hölderlin Gelegenheit
            gehabt haben, die Landschaften um die Großstadt herum zu
            besichtigen. Womöglich ist er dem Flussverlauf der Garonne sogar
            selbst gefolgt und hat bei Soulac-sur-Mer das erste und einzige Mal
            in seinem Leben das weite Meer erblickt, das er in seinen Werken
            schon zuvor immer wieder imaginiert hat.
          </Paragraph>
        </Box>
        <Box sx={{ clear: "left" }}>
          <Note title="Übrigens">
            Aufgrund des sichelförmigen Bogens, in dem sich die Garonne durch
            Bordeaux windet, wird die Stadt auch ›Port de la lune‹ (Hafen des
            Mondes) genannt. Dass die Garonne über die Gironde direkt mit dem
            Meer verbunden ist, macht sich in Bordeaux tatsächlich bemerkbar:
            Bei Flut sorgt das einströmende Meerwasser dafür, dass der Pegel der
            Garonne um etwa 4–5 Meter ansteigt. Die Strömungen sorgen für
            Strudel und Unruhe auf der Wasseroberfläche. Manchmal bewegen sich
            die Gezeitenwellen dutzende Kilometer flussaufwärts bis in die
            Vororte von Bordeaux. Diese sogenannte »Springflut« (mascaret) ist
            vor allem bei Surfern sehr beliebt.
          </Note>
        </Box>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
